import moment from 'moment';
import { phoneValidated, phoneValidationFailed } from 'state/modules/phone/actions';
import { INVALID_PHONE_ERROR_CODE } from '_config/errorCodes';
import { sendConfirmationEvent, sendGuestDetailsEvent, sendPaymentEvent, sendPropertySelectedEvent, sendRateSelectedEvent } from 'utils/analytics';
import { deleteCartCookie } from 'utils/cookies/deleteCookie';
import { setCartCookie, setReloadBookingCookie } from 'utils/cookies/setCookie';
import types from './types';

const { ADD_SUCCESS, BOOK_SUCCESS, CLEAN_UP, SAVE_GUEST_SUCCESS, SAVE_GUEST_FAIL, SAVE_PAYMENT_SUCCESS, SAVE_RATE_SUCCESS } = types;

export default () => ({ dispatch }) => next => action => {
  switch (action.type) {
    case ADD_SUCCESS: {
      const { id, expireAt, property } = action.result;
      // Submit data to Google Analytics
      sendPropertySelectedEvent(property);
      // Store the Cart ID in cookie
      setCartCookie(id, moment(expireAt).subtract(30, 'seconds').toDate());
      break;
    }
    case BOOK_SUCCESS: {
      // Set reload booking cookie
      setReloadBookingCookie();
      // Submit data to Google Analytics
      sendConfirmationEvent(action.result?.property);
      break;
    }
    case CLEAN_UP: {
      deleteCartCookie();
      break;
    }
    case SAVE_GUEST_SUCCESS: {
      // Submit data to Google Analytics
      sendGuestDetailsEvent(action.result?.property, action.result?.guest);
      // If guest details were successfully added to a cart, then we can safely assume the phone number is valid
      dispatch(phoneValidated());
      break;
    }
    case SAVE_GUEST_FAIL: {
      if (action.error.code === INVALID_PHONE_ERROR_CODE) {
        // If an phone validation error was triggered, then set phone validation failed to render the error in the phone input form field
        dispatch(phoneValidationFailed(action.error));
      }
      break;
    }
    case SAVE_PAYMENT_SUCCESS: {
      // Send to Google Analytics
      // TODO: Verify that folio.property is correct
      if (action.result?.property) {
        sendPaymentEvent(action.result?.property);
      }
      break;
    }
    case SAVE_RATE_SUCCESS: {
      // Submit data to Google Analytics
      const rate = action.result?.property?.options?.find(option => option.id === action.result?.rateOptionId);
      sendRateSelectedEvent(action.result?.property, rate);
      break;
    }
    default: {
      break;
    }
  }
  next(action);
};
