import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import moment from 'moment';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { loadableReady } from '@loadable/component';

import configureStore from 'state/create';
import ApiClient from 'helpers/ApiClient';
import ScrollToTop from 'helpers/ScrollToTop';
import registerServiceWorker from 'helpers/ServiceWorker';
import momentDateFormats from '_config/constants/momentDateFormats';
import fontawesomeInit from 'theme/fontawesomeInit';
import App from 'facades/App/App';

// Update date display format for both locales
moment.updateLocale('en', momentDateFormats.en);
moment.updateLocale('ja', momentDateFormats.ja);

// Set up all the initial stuff
const pathName = window?.location?.pathname ?? '';
const localeCode = pathName.startsWith('/ja') ? 'ja' : 'en';
moment.locale(localeCode);
const initialState = window.__INITIAL_STATE__; // Get the initial state from server-side rendering
// eslint-disable-next-line no-underscore-dangle
const messages = window.__MESSAGES__;
const { auth, tenant: { id: tenantId, guestSourceName, config: { swAssets } } } = initialState;
const guestId = auth?.guest?.id;
const client = new ApiClient({ locale: localeCode, tenantId, guestSourceName, guestId });
const { store } = configureStore({ initialState, client });

// Register the service worker for cache and offline functionality
registerServiceWorker({ locale: localeCode, tenantId, tenantAssets: swAssets });

// Check if PluralRules is available, old browsers IE11, Chrome v.<=68 and Safari doesn't support it
if (!Intl.PluralRules) {
  // Enable the PluralRules polyfill
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/polyfill');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/en');
  // eslint-disable-next-line global-require
  require('@formatjs/intl-pluralrules/locale-data/ja');
}

fontawesomeInit();

function render() {
  const rootElement = document.getElementById('react-view');
  hydrateRoot(
    rootElement,
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop>
          <App locale={localeCode} messages={messages} />
        </ScrollToTop>
      </Provider>
    </BrowserRouter>,
  );
}

// loadable-component setup
loadableReady(() => {
  render();
});
