import { cartCookieConfig, consentCookieConfig, reloadBookingsCookieConfig } from '_config/constants/cookies';
import { getCookieDomain } from 'utils/misc';

const SECURE = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test';

export function setCookie(name, value, expireAt) {
  const domain = getCookieDomain(window.location.hostname);

  const attributes = [
    `${name}=${value};`,
    `domain=${domain};`,
    `secure=${SECURE};`,
    'path=/',
    'samesite=none',
  ];

  if (expireAt) {
    // max-age is used in Safari/Firefox, expires is used in Chrome
    const maxAge = (expireAt - new Date()) / 1000;
    attributes.push(`expires=${expireAt}`);
    attributes.push(`max-age=${maxAge}`);
  }

  document.cookie = attributes.join(';');
}

export function setAcceptCookie() {
  const expireAt = new Date();
  expireAt.setSeconds(expireAt.getSeconds() + consentCookieConfig.maxAge);
  setCookie(consentCookieConfig.name, consentCookieConfig.value, expireAt);
}

export function setReloadBookingCookie() {
  setCookie(reloadBookingsCookieConfig.name, reloadBookingsCookieConfig.value);
}

export function setCartCookie(value, expireAt) {
  setCookie(cartCookieConfig.name, value, expireAt);
}
